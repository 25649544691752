import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../06 Utilities/linkResolver'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

function renderCurated(slice) {
  return slice.fields.map((item, index) =>
  <>
      <Link className="content-blocks__item" key={index} to={linkResolver(item.link)}>
          {item.link.hero_image ? 
          <Img className="content-blocks__image" fluid={item.link.hero_imageSharp.childImageSharp.fluid} alt={item.link.hero_image.alt} />
          : ""}
          <div className="content-blocks__copy">
            {item.short_name ? 
            <h3><span>{item.link.numeral ? item.link.numeral : ""}</span>{item.link.short_name}</h3>
            :
            <h3><span>{item.link.numeral ? item.link.numeral : ""}</span>{RichText.asText(item.link.title)}</h3>
            }
            <span className="cta">Read more</span>
          </div>
      </Link>  
      </>
  )
}

export default ({ slice }) =>
  <>
    <div className="wrapper">
      <div className="content-blocks">
        {slice.primary.section_title ? 
        <h2>{RichText.asText(slice.primary.section_title)}</h2>
        : ""}
        {slice.primary.section_title ? 
        <h3>{RichText.asText(slice.primary.section_introduction)}</h3>
        : ""}
        <div className={slice.fields.length === 3 ? "content-blocks__items" : "content-blocks__items--grid"}>
          {renderCurated(slice)}
        </div>
        {slice.primary.link ? 
        <Link className="button button--primary" to={linkResolver(slice.primary.link)}>{slice.primary.link_text}</Link>
        : ""}
      </div>
    </div>
  </>