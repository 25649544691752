import React from 'react'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby';
import Inspiration from '../03 Organisms/ContentBlocksSlice'
import AutoBlockSlice from '../03 Organisms/AutoBlockSlice';
import ContentSlice from '../03 Organisms/ContentSlice';
import InlineImage from '../03 Organisms/InlineImageSlice';
import ContentBlocks from '../03 Organisms/ContentBlockInspirationSlice';
import Layout from '../04 Templates/layout'

export const query = graphql`
query DestPageQuery($uid: String!) {  
    prismic {
      allDestinations(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              type
              tags
            }
            short_name
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1900) {
                    ...GatsbyImageSharpFluid
                }
              }
            }            
            content
            title
            introduction
            body {
              ... on PRISMIC_DestinationBodyAuto_content_feed {
                type
                label
                primary {
                  heading
                  intro
                }
              }
              ... on PRISMIC_DestinationBodyContent_blocks {
                type
                label
                fields {
                  category
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                  }                       
                  title1
                  link {
                    ... on PRISMIC_Inspiration {
                      numeral
                      title
                      _meta {
                        uid
                        type
                      }
                      hero_image
                      hero_imageSharp {
                        childImageSharp {
                          fluid(maxWidth: 900) {
                              ...GatsbyImageSharpFluid
                          }
                        }
                      }                        
                      introduction
                    }
                  }
                }
                primary {
                  section_title
                  section_introduction
                  link {
                    ... on PRISMIC_Landing_page {
                      title
                      introduction
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                }
              }           
              ... on PRISMIC_DestinationBodyContent {
                type
                label
                primary {
                  content
                }
              }
              ... on PRISMIC_DestinationBodyInline_image {
                type
                label
                primary {
                  caption
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                  }                    
                }
              }              
            }
          }
        }
      }
        allInspirations(sortBy: meta_firstPublicationDate_ASC) {
            edges {
              node {
                destination {
                  ... on PRISMIC_Landing_page {
                    title
                    introduction
                    _meta {
                      type
                      uid
                    }
                  }                                          
                  ... on PRISMIC_Destination {
                    hero_image
                    title
                    short_name
                    _meta {
                      tags
                      uid
                      type
                    }
                  }
                } 
                hero_image
                hero_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 1900) {
                        ...GatsbyImageSharpFluid
                    }
                  }
                }
                title
                numeral
                introduction
                _meta {
                  tags
                  type
                  uid
                }
              }
            }
          }
    } 
}
`

const RenderSlices = ({ current, slices, content, insta }) => {
    if (!slices || !content) return null;
    return slices.map((slice, index) => {
      const res = (() => {
        switch(slice.type) {
          case 'inspiration_links': return (
  
              <section key={index} className="homepage-slice-wrapper">
                <Inspiration slice={slice} />
              </section>
  
          )

          case 'auto_content_feed': return (
  
            <section key={index} className="homepage-slice-wrapper">
              <AutoBlockSlice current={current._meta.uid} log={console.log(content)} content={content} slice={slice} />
            </section>

          )
          
          // case 'gallery': return (
  
          //   <section key={index} className="homepage-slice-wrapper">
          //     <GalleryFeedSlice content={content} insta={insta} />
          //   </section>

          // )

          case 'content': return (

            <section key={index} className="homepage-slice-wrapper content-slice">
                <ContentSlice slice={slice} />
            </section>

          )
        
          case 'inline_image': return (

            <section key={index} className="homepage-slice-wrapper image-slice">
                <InlineImage slice={slice} />
            </section>

          )          
          
          case 'content_blocks': return (

            <section key={index} className="homepage-slice-wrapper image-slice">
                <ContentBlocks slice={slice} />
            </section>

          )   

          default: return

        }
      }
      )();
      return res;
    })
  }

  const RenderIndexBody = ({ insta, home, slices, content }) => (
    <React.Fragment>
        {home.content ? 
        <>
        <section className="homepage-slice-wrapper content-slice">
          <div className="wrapper">
            <div className="content">
              {RichText.render(home.content)}
            </div>
          </div>
        </section>
        <div className="horizontal-rule"></div>
        </>
        : "" }
        <RenderSlices current={home} content={content} slices={home.body} />
    </React.Fragment>
  );
  
  const Destination = props => {
    const doc = props.data.prismic.allDestinations.edges.slice(0,1).pop();
    const content = props.data.prismic.allInspirations.edges;

    if(!doc) return null;
    return(
      <Layout data={doc}>
        <RenderIndexBody home={doc.node} content={content} />
      </Layout>
    )
  }
  
  export default Destination;